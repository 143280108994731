<template>
  <div>

    <div class="fatherBox">
      <div class="headerBox headerinfos">
        <p @click="$router.go(-1)">返回</p>
        <p>/</p>
        <p>提现记录</p>
      </div>
      <el-table :data="list" border style="width: 98%" >
        <el-table-column prop="id" label="序号"></el-table-column>
        <el-table-column prop="txtime" label="提现时间"></el-table-column>
        <el-table-column prop="tmoney" label="提现金额"></el-table-column>
        <el-table-column prop="account" label="提现账户"></el-table-column>
        <el-table-column prop="status" label="提现状态">
          <template slot-scope="scope">
            <p class="aa" v-if="scope.row.status == '0'" @click="withdrawal_status(scope.row.id)">待审核</p>
            <p class="aa" v-if="scope.row.status == '1'" @click="withdrawal_status(scope.row.id)">已审核 请邮寄发票</p>
            <p class="aa" v-if="scope.row.status == '3'" @click="withdrawal_status(scope.row.id)">邮寄发票 审批中</p>
            <p class="aa" v-if="scope.row.status == '4'" @click="withdrawal_status(scope.row.id)">财务未通过 可补发发票</p>
            <p class="aa" v-if="scope.row.status == '5'" @click="withdrawal_status(scope.row.id)">通过（打款）</p>
            <p class="aa" v-if="scope.row.status == '6'" @click="withdrawal_status(scope.row.id)">已拒绝 退回到代理余额</p>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>

      <div class="block">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="currpage" :page-size="pagesize"
          background layout="total,prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="提现" :visible.sync="dialogFormVisible" top="50px" :close-on-click-modal="false">
      <el-form ref="articleForm" style="width:300px;margin:0 auto">
        <el-form-item label="进度" style="margin:0 auto">

          <el-steps :active="active" direction="vertical" finish-status="success" class="step">
            <el-step :title="item.title" :key="index" v-for="(item,index) in operate">
              <template slot="description">
                <p v-if="item.status == 2">
                  <el-button @click="expressNoVisible = true" style="color:#00BFBF">
                    {{item.status_title}}</el-button>
                </p>
                <p v-else>{{item.status_title}}</p>
                {{item.createTime}}
              </template>
            </el-step>
          </el-steps>

        </el-form-item>

        <el-form-item style="margin-top:5px">
          <p @click="agentCompanyFun" class="aa">邮寄地址？</p>
        </el-form-item>
      </el-form>

    </el-dialog>
    <el-dialog title="邮寄地址" :visible.sync="dialogAddressVisible" top="50px" :close-on-click-modal="false">
      <el-form ref="articleForm" style="width:300px;margin:0 auto">
        <el-form-item label="收件人：">
          {{ mailing_address.name }}
        </el-form-item>
        <el-form-item label="联系方式：">
          {{ mailing_address.contact }}
        </el-form-item>
        <el-form-item label="地址：">
          {{ mailing_address.address }}
        </el-form-item>

      </el-form>

    </el-dialog>

    <el-dialog title="填写快递单号" :visible.sync="expressNoVisible" top="50px" :close-on-click-modal="false">
      <el-form ref="articleForm" style="width:460px;margin:0 auto">
        <el-form-item label="快递单号：">
          <el-select v-model="logistics_data.shipper_code" placeholder="请选择" style="width: 260px; float: left;">
            <el-option v-for="item in logistic" :key="item.id" :label="item.express_name" :value="item.express_code">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="快递单号：">
          <el-input style="width: 260px; float: left" placeholder="请输入快递单号" v-model="logistics_data.express_number">
          </el-input>
        </el-form-item>

        <el-form-item style="margin-top:5px">
          <el-button @click="expressNoVisible = false" style="margin-left: 20px;">取消</el-button>
          <el-button type="primary" @click="confirm()" style="margin-left: 180px;">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import {
    extractApplyList,
    extractApplyRecord,
    logisticsPt,
    logisticsNum,
    agentCompany
  } from '@/api/agentApi.js'

  export default {
    data() {
      return {
        list: [], // 列表
        date: '',
        currpage: 1, // 初始页
        pagesize: 10, // 每页显示条目个数
        CountNum: 0, // 总条数
        isContinue: 0,
        dialogFormVisible: false, // 弹窗
        dialogAddressVisible: false, // 弹窗
        expressNoVisible: false,
        operate: [],
        wid: '',
        active: 0,
        mailing_address: {
          name: '',
          contact: '',
          address: ''
        },
        express_no: '',
        logistic: [],
        logistics_data: {
          id: '',
          type: '',
          shipper_code: '',
          express_number: '',
        }

      }
    },

    mounted() {
      // this.date = this.$route.params.date; // 学生id赋值😜
      this.extractApplyList(this.currpage); // 查询省市区
      this.get_logistics();
    },
    methods: {
      //列表数据
      extractApplyList(currpage) {
        this.loading = true
        const data = {
          limit: this.pagesize,
          page: this.currpage,

        }
        extractApplyList(data).then(res => {
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.list = res.data.List;
          this.isContinue = res.data.IsContinue
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      //状态循环
      withdrawal_status(wid) {
        this.wid = wid;
        this.logistics_data.id = wid;
        this.dialogFormVisible = true
        const data = {
          id: wid
        };
        extractApplyRecord(data).then(res => {

          this.operate = [];
          if (res.status.code == 1) {
            var list = res.data.List;
            for (var i = 0; i < list.length; i++) {
              this.logistics_data.type = list[i]['type'];
              var status_title = '';
              if (list[i]['status'] == 0) {
                status_title = list[i]['msg'];
              } else if (list[i]['status'] == 1) {
                status_title = list[i]['msg'];

              } else if (list[i]['status'] == 2) {
                status_title = list[i]['msg'];
              } else if (list[i]['status'] == 3) {
                status_title = list[i]['msg'];
              } else if (list[i]['status'] == 4) {
                status_title = list[i]['msg'];
              } else if (list[i]['status'] == 5) {
                status_title = list[i]['msg'];
              } else if (list[i]['status'] == 6) {
                status_title = list[i]['msg'];
              }

              this.active = i + 1
              var obj = {
                //传参
                historyId: list[i]['id'],
                status: list[i]['status'],
                createTime: list[i]['uptime'],
                status_title: status_title
              };
              this.operate.push(obj);
            }
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      //物流 平台
      get_logistics() {
        const data = {};
        logisticsPt(data).then(res => {

          if (res.status.code == 1) {
            this.logistic = res.data;

          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      confirm() {
        if (this.logistics_data.shipper_code == '') {
          this.$message.error("物流平台不能为空");
        } else if (this.logistics_data.express_number == '') {
          this.$message.error("物流号不能为空");
        } else if (this.logistics_data.type == '') {
          this.$message.error("提现到类型不能为空");
        } else if (this.logistics_data.id == '') {
          this.$message.error("提现申请id不能为空");
        } else {
          const data = this.logistics_data;
          logisticsNum(data)
            .then((res) => {
              console.log(data);
              if (res.status.code == 1) {
                this.withdrawal_status(this.logistics_data.id);
                this.expressNoVisible = false;
              } else {
                this.$message.error(res.status.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err);
            });
        }
      },

      handleCurrentChange(val) { // 分页

        if (this.isContinue == 1) {
          this.currpage = val
          this.extractApplyList(val)
        }
      },
      //查询邮寄地址
      agentCompanyFun() {
        this.dialogAddressVisible = true
        agentCompany().then(res => {
          this.mailing_address.name      = res.data.name
          this.mailing_address.contact   = res.data.tel
          this.mailing_address.address   = res.data.addr
        }).catch(err => {
          console.log(err)
        })
      },
    }
  }

</script>

<style lang="scss" scoped>
  #body {
    // height: 100%;
    // background: #f0f2f5;
  }

  .fatherBox {
    width: 98%;
    // height: 800px;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      // justify-content: space-between;
      // border-bottom: 2px solid #e9e9e9;

      p {
        font-size: 16px;
        font-family: 萍方-简;
        font-weight: normal;
        color: #000000;
        // margin-left: 32px;
      }


    }

    .headerinfos {
      display: flex;

      p {
        color: burlywood;

      }

      p:nth-child(3) {
        cursor: pointer;
        color: black;
      }
    }

  }

  .aa {
    cursor: pointer;
  }


</style>
